import React, { createContext, useEffect, useRef, useState } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { useTranslation } from "react-i18next";
import { default as config } from "../../app/constants/config.json";

import "semantic-ui-css/semantic.min.css";
import "../styles/index.scss";
import "../styles/ui.grid.css";
import "./Layout.css";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import { details, login, register, forgot } from "../crud/user";
import { Button, Form, Input, Modal, Tab } from "semantic-ui-react";
import { storeGet, storeSet } from "../crud/storage";
// import PWAPrompt from "../components/PWA-Prompt/PWA-Prompt";
import { useHistory, useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";

export const UserContext = createContext(null);

function Layout({ children }) {
  let history = useHistory();
  let location = useLocation();

  /* useEffect(() => {
    setTimeout(() => {
      var head = document.head;
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      let stylePath =
        "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
      link.href = stylePath;
      head.appendChild(link);
    }, 1000);
  }, []); */

  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [openedUserModal, setOpenedUserModal] = useState(false);
  const [userFormLoading, setUserFormLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState("0");
  const [submittingQoute, setSubmittingQoute] = useState(false);
  const [modalSuccessCallback, setModalSuccessCallback] = useState();
  const [userFormValues, setUserFormValues] = useState({
    name: "",
    mobile: "",
    email: "",
  });

  useEffect(() => {
    window.gtag("config", config.GoogleAnalytics.code, {
      page_path: window.location.hash,
    });
  }, [location]);

  const _userDetails = () => {
    details()
      .then((response) => {
        setUserData(response.data.success);
      })
      .catch((error) => {
        setSubmittingQoute(false);
        if (error?.response?.status === 401) {
          storeSet("user", null);
        } else {
          if (error?.response?.data && error?.response?.data?.message) {
            toast({
              type: "error",
              title: error.response.data.message,
              animation: "bounce",
              time: 5000,
            });
          }
        }
      });
  };

  useEffect(() => {
    const userStorage = storeGet("user");
    if (userStorage && userStorage.token) {
      _userDetails();
    }
  }, []);

  const userFieldChanged = (e) => {
    let field = {};
    field[e.target.name] = e.target.value;
    let values = { ...userFormValues, ...field };
    setUserFormValues(values);
  };

  const userActions = {
    openUserModal: ({ successCallback, successParams }) => {
      setOpenedUserModal(true);
      setUserFormLoading(false);
      setModalSuccessCallback({
        successCallback: successCallback,
        successParams: successParams,
      });
    },
    logout: () => {
      storeSet("user", null);
      setUserData(null);
      history.push("/");
    },
  };

  const loginEmailEl = useRef();
  const _forgot = (e) => {
    e.preventDefault();
    if (userFormValues.email) {
      setUserFormLoading(true);
      forgot(userFormValues.email)
        .then((response) => {
          setUserFormLoading(false);
          toast({
            type: "success",
            title: response.data.msg,
            animation: "bounce",
            time: 5000,
          });
        })
        .catch((error) => {
          setUserFormLoading(false);
          if (error.response.data && error.response.data.message) {
            toast({
              type: "error",
              title: error.response.data.message,
              animation: "bounce",
              time: 5000,
            });
          }
        });
    } else {
      loginEmailEl.current.focus();
    }
  };

  const _register = () => {
    setUserFormLoading(true);
    register(userFormValues)
      .then((response) => {
        // if (modalSuccessCallback) {
        //   modalSuccessCallback.successCallback(
        //     modalSuccessCallback.successParams
        //   );
        //   console.log('A');
        // }

        setTimeout(
          () => {
            setOpenedUserModal(false);

            if (response.data && response.data.success) {
              toast({
                type: "success",
                title:
                  "Please check your inbox and verify your email address and complete your registration",
                animation: "bounce",
                time: 5000,
              });
            }
          },
          userData ? 0 : 1000
        );

        //window.setTimeout(_userDetails, 2000);
      })
      .catch((error) => {
        setUserFormLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast({
            type: "error",
            title: error.response.data.error,
            animation: "bounce",
            time: 5000,
          });
        }
      });
  };

  const _login = () => {
    setUserFormLoading(true);
    login(userFormValues)
      .then((response) => {
        setOpenedUserModal(false);
        _userDetails();
        if (response.data && response.data.success) {
          toast({
            type: "success",
            title: "The user has been logined successfully",
            animation: "bounce",
            time: 5000,
          });
          //history.push("/panel");
        }
        /* if (modalSuccessCallback) {
          modalSuccessCallback.successCallback(
            modalSuccessCallback.successParams
          );
        } */
      })
      .catch((error) => {
        setUserFormLoading(false);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error
        ) {
          toast({
            type: "error",
            title: error.response.data.error,
            animation: "bounce",
            time: 5000,
          });
        }
      });
  };

  const handleTabChange = (e, { activeIndex }) => setActiveIndex(activeIndex);

  return (
    <>
      <>
        <Header />
        {children}
        {/* <LazyLoad once> */}
        <Footer />
        {/* </LazyLoad> */}

        <SemanticToastContainer position="bottom-right" />
      </>
    </>
  );
}

export default Layout;
