import React, { createContext, useEffect, useRef, useState } from "react";
import Header from "./Header/NHeader";
import Footer from "./Footer/Footer";
import { useTranslation } from "react-i18next";
import { default as config } from "../constants/config.json";

import "semantic-ui-css/semantic.min.css";
import "../styles/index.scss";
import "../styles/ui.grid.css";
import "./Layout.css";

import { SemanticToastContainer, toast } from "react-semantic-toasts";
import "react-semantic-toasts/styles/react-semantic-alert.css";
import { details, login, register, forgot } from "../crud/user";
import {
  Button,
  Form,
  Input,
  Modal,
  Tab,
  Dropdown,
  Grid,
  Segment,
  Icon,
  Image,
  Menu,
} from "semantic-ui-react";
import { storeGet, storeSet } from "../crud/storage";
// import PWAPrompt from "../components/PWA-Prompt/PWA-Prompt";
import { useHistory, useLocation } from "react-router-dom";
import LazyLoad from "react-lazyload";
import Slider from "../components/SliderQoute/index.js";
export const UserContext = createContext(null);

function Layout({ children }) {
  let history = useHistory();
  let location = useLocation();

  /* useEffect(() => {
    setTimeout(() => {
      var head = document.head;
      var link = document.createElement("link");
      link.type = "text/css";
      link.rel = "stylesheet";
      let stylePath =
        "https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css";
      link.href = stylePath;
      head.appendChild(link);
    }, 1000);
  }, []); */

  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const [openedUserModal, setOpenedUserModal] = useState(false);
  const [userFormLoading, setUserFormLoading] = useState(false);
  const [activeIndex, setActiveIndex] = useState("0");
  const [submittingQoute, setSubmittingQoute] = useState(false);
  const [modalSuccessCallback, setModalSuccessCallback] = useState();
  const [userFormValues, setUserFormValues] = useState({
    name: "",
    mobile: "",
    email: "",
  });

  useEffect(() => {
    window.gtag("config", config.GoogleAnalytics.code, {
      page_path: window.location.hash,
    });
  }, [location]);

  const _userDetails = () => {
    details()
      .then((response) => {
        setUserData(response.data.success);
      })
      .catch((error) => {
        setSubmittingQoute(false);
        if (error?.response?.status === 401) {
          storeSet("user", null);
        } else {
          if (error?.response?.data && error?.response?.data?.message) {
            toast({
              type: "error",
              title: error.response.data.message,
              animation: "bounce",
              time: 5000,
            });
          }
        }
      });
  };

  useEffect(() => {
    const userStorage = storeGet("user");

    if (userStorage && userStorage.token) {
      _userDetails();
    }
  }, [location]);

  const userActions = {
    openUserModal: ({ successCallback, successParams }) => {
      setOpenedUserModal(true);
      setUserFormLoading(false);
      setModalSuccessCallback({
        successCallback: successCallback,
        successParams: successParams,
      });
    },
    logout: () => {
      storeSet("user", null);
      setUserData(null);
      history.push("/");
    },
  };

  const loginEmailEl = useRef();
  const _forgot = (e) => {
    e.preventDefault();
    if (userFormValues.email) {
      setUserFormLoading(true);
      forgot(userFormValues.email)
        .then((response) => {
          setUserFormLoading(false);
          toast({
            type: "success",
            title: response.data.msg,
            animation: "bounce",
            time: 5000,
          });
        })
        .catch((error) => {
          setUserFormLoading(false);
          if (error.response.data && error.response.data.message) {
            toast({
              type: "error",
              title: error.response.data.message,
              animation: "bounce",
              time: 5000,
            });
          }
        });
    } else {
      loginEmailEl.current.focus();
    }
  };

  return (
    <>
      <UserContext.Provider value={{ userData, userActions }}>
        {/* <Grid
          columns='equal'
          style={{
            margin: '0px',
            height: '100%',
            background: '#105399',
            position: 'fixed',
          }}
          inverted
        >
          <Grid.Row
            textAlign='center'
            style={{ padding: '0px', height: '100%' }}
          >
            <div className='topGradient'></div>
            <Grid.Column
              style={{ background: '#fff', height: '100vh', zIndex: '2' }}
            >
              <Header />

              <div className='childern'>{children}</div>
              <div className='policy'>
                <p className='policytext'>Terms of use   |    Privacy policy </p>
              </div>
            </Grid.Column>
            <Grid.Column
              only='tablet computer'
              className='center'
              style={{ height: '100vh', zIndex: '2' }}
            >
              {' '}
              <Slider />
            </Grid.Column>
          </Grid.Row>
        </Grid> */}
        {children}
      </UserContext.Provider>
    </>
  );
}

export default Layout;
