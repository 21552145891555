import React, { useState } from "react";
import "./index.css";
import { Icon } from "semantic-ui-react";

const Slide = ({ image, listItem, id, slidePrev, slideNext, items, setCurrentSlide }) => {
  const container = {
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  };

  return (
    <React.Fragment>
      <div className="page-body">
        <div className="header">
          <div className="slider-title font-svg">{image.title}</div>
        </div>
        <div className="slider-container">
          <div className="image-slider-main">
            <img
              src={image.link}
              alt="Slider_image"
              className="responsive-image"
            />
          </div>
        </div>
        <div className="column_block">
          <div className="sixteen wide column_dots">
            <Icon
              name="left arrow"
              onClick={slidePrev}
              className="arrow-icon left-arrow"
            />
            <div style={container}>
              {items.map((slide, index) => (
                <span
                  key={slide.id}
                  className="dot"
                  style={{ background: id.id === slide.id ? "white" : "" }}
                  onClick={() => setCurrentSlide(index)}
                ></span>
              ))}
            </div>
            <Icon
              name="right arrow"
              onClick={slideNext}
              className="arrow-icon right-arrow"
            />
          </div>
        </div>
        <div className="slider-footer">
          <div className="description">
            <div className="fontDes ">{image.des}</div>
          </div>
          <div className="features-list">
            <ul>
              {listItem.list_items.map((item) => (
                <li key={item.li}>{item.li}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const App = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      id: 1,
      title: "Compare the most reliable insurance companies",
      des: "It will only take a minute, but you can save big!",
      link: "./assets/images/new/slide1.png",
      list_items: [
        { li: "Learn more about what the best insurance companies have to offer." },
        { li: "Compare the insurance companies and their products to save money." },
        { li: "Save up to 70% simply by comparing the insurance company." },
      ],
    },
    {
      id: 2,
      title: "Compare the rates and policies to find proper coverage.",
      des: "Policy and rate information at your fingertips.",
      link: "./assets/images/new/slide2.png",
      list_items: [
        { li: "Instantly compare rates and policies." },
        { li: "Smart rating system for proper comparison." },
        { li: "Sort your options in the most convenient way." },
      ],
    },
    {
      id: 3,
      title: "Use our intelligent insurance advisor!",
      des: "The insurance solution that works.",
      link: "./assets/images/new/slide3.png",
      list_items: [
        { li: "Let our virtual advisor guide you" },
        { li: "Get personalized recommendation." },
        { li: "Find the right policy" },
      ],
    },
  ];

  const slideNext = () => {
    setCurrentSlide((prev) => (prev + 1 === slides.length ? 0 : prev + 1));
  };

  const slidePrev = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  return (
    <React.Fragment>
      <Slide
        image={slides[currentSlide]}
        listItem={slides[currentSlide]}
        id={slides[currentSlide]}
        slideNext={slideNext}
        slidePrev={slidePrev}
        items={slides}
        setCurrentSlide={setCurrentSlide}
      />
    </React.Fragment>
  );
};

export default App;